import React from "react"
import {
  ActivityBox,
  DiscussBox,
  DefinitionBox,
  ToSeeBox,
  CopyrightBox
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import { withPrefix } from 'gatsby'
import canterburyFrame from './canterbury.png'
import { Video, YouTubeVideo } from '../../../components/video'
import { StaticImage } from 'gatsby-plugin-image'

export default () => (
  <Layout title="Chaucer">
    <SmallSectionHeader
      color={theme.colors.red.dark}
      image={headerImage}
      to="/pilgrimage"
    >
      Pilgrimage
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/pilgrimage', name: 'Pilgrimage' },
      { name: 'Chaucer' }
    ]} />
    <Page>
      <h1>Chaucer</h1>
      <p className="page-left">
        Geoffrey Chaucer was born in 1342 in London, the son of a wealthy wine merchant. He joined the court of King Edward III, and the King appointed him Controller of Customs where he was responsible for the taxes on the wool, sheepskin and leather coming into the Port of London. He was the author of the very well known ‘Canterbury Tales’ which recounts the journey of a group of pilgrims travelling from London to Canterbury.
      </p>
      <Figure
        caption="Chaucer’s Canterbury Pilgrims by William Blake – Courtesy of John Windle Antiquarian Bookseller."
        className="page-right"
      >
        <StaticImage
          src="./chaucers-pilgrims.png"
          alt="Chaucer's Canterbury Pilgrims"
        />
      </Figure>
      <hr />

      <h2>Chaucer’s Pilgrims</h2>
      <p className="page-left">
        In Chaucer’s time, Canterbury was dominated by the Cathedral which drew pilgrims from all walks of life. Some of these pilgrims would have been fairly local and their journey perhaps only taken a day or two, whereas others may have travelled from much further afield.
      </p>
      <Figure
        caption="An artist’s impression of what Cantebury may have looked like shortly after the time of Chaucer’s ‘Canterbury Tales”. – Courtesy of University of York."
        className="page-right row-3"
      >
        <StaticImage
          src="./precincts.png"
          alt="3D representation of an aeriel view of Canterbury Cathedral"
        />
      </Figure>
      <p className="page-left">
        Whereas in earlier centuries many pilgrims would have stayed in the monastery guesthouses, by the fourteenth century they often stayed in inns and hostels in the city centre. A whole industry had grown up around looking after the large numbers of pilgrims arriving to visit the shrine of Thomas Becket.
      </p>
      <p className="page-left">
        The Cathedral provided work for many craftspeople, in fact the Nave was being completely rebuilt at the same time that the ‘Canterbury Tales’ was being written.
      </p>

      <DiscussBox>
        What does Chaucer’s Canterbury Tales tell us about the importance of pilgrimage in medieval life?
      </DiscussBox>

      <p className="page-left">
        The fictional journey from the Tabard Inn in Southwark (London), to Canterbury would have taken Chaucer’s pilgrims three or four days. In the story, Chaucer explains that to pass the time on their journey, each pilgrim was invited to tell a story to entertain the group. In the end, Chaucer never finished writing the Tales but the book is celebrated for the picture it paints of medieval life and pilgrimage.
      </p>
      <Figure
        caption="The Corona Chapel at Canterbury Cathedral – Reconstruction courtesy of University of York"
        className="page-right"
      >
        <YouTubeVideo id="JVGopD5VuF0" />
      </Figure>
      <p className="page-left">
        Chaucer’s pilgrims represent a cross section of medieval society from a high ranking knight to the lowly cook and reflect the variety of people who would have undertaken pilgrimage in this period. The description of each character, along with the story they recount, can tell us something about medieval life, stereotypes and concerns.
      </p>
      <DefinitionBox title="Secular" className="page-right">
        Not connected with religion or spiritual matters.
      </DefinitionBox>

      <DiscussBox>
        Do you think all of Chaucer’s pilgrims were on pilgrimage for the same reasons?
      </DiscussBox>

      <ToSeeBox
        items={[
          {
            location: "Outside the Cathedral",
            items: [
              {
                title: "Tombs of the Black Prince",
                content:
                  "This royal prince was buried in Canterbury Cathedral at the time of Chaucer.",
              },
            ],
          },
          {
            location: "Crypt",
            items: [
              { title: "Crypt", content: "The site of Becket's original tomb and shrine." },
            ],
          },
          {
            location: "Trinity Chapel",
            items: [
              {
                title: "Trinity Chapel",
                content: "Built to house Becket's shrine.",
              },
            ],
          },
          {
            location: "Corona",
            items: [
              {
                title: "Shrine of Becket's head",
                content:
                  "A piece of Becket’s scalp removed by one of the murderer’s sword was held in a silver gilt reliquary. Pilgrims looked at the relic through a crystal in the crown.",
              },
            ],
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="Pilgrimage activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/Pilgrimage.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘Pilgrimage’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "What is pilgrimage?", to: "/pilgrimage/what-is-pilgrimage" },
          {
            name: "Medieval pilgrims",
            to: "/pilgrimage/medieval-pilgrimage",
          },
          { name: "Pilgrimage today", to: "/pilgrimage/pilgrimage-today" },
        ]}
      />

      <hr />

      <CopyrightBox />

    </Page>
  </Layout>
)
